import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
// _mock
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
//
import { useSnackbar } from 'notistack';
import axiosInstance from 'src/utils/axios';
import { AddressNewForm, AddressItem } from '../../address';
import CheckoutSummary from './checkout-summary';
import { useUserAddress } from '../../../api/userAddress';

// ----------------------------------------------------------------------

export default function CheckoutBillingAddress({ checkout, onBackStep, onCreateBilling, withSummary=true }) {
  const addressForm = useBoolean();
  const { addressList, mutateUserAddress } = useUserAddress();
  const { enqueueSnackbar } = useSnackbar();
  
  const handleDeleteAddress = async (address) => {
    const { id } = address || {};
    const response = await axiosInstance.delete(`/api/v2/auth/client-users/address?id=${id}`);
    if (response?.data?.success) {
      console.info('DATA', response?.data);
      enqueueSnackbar('Address deleted successfully!');
      mutateUserAddress()
    } else {
      enqueueSnackbar(response?.data?.error?.toString());
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={withSummary ? 8 : 12}>
          {addressList.map((address) => (
            <AddressItem
              key={address.id}
              address={address}
              action={
                <Stack flexDirection="row" flexWrap="wrap" flexShrink={0}>
                  {!address.primary && (
                    <Button size="small" color="error" sx={{ mr: 1 }} onClick={() => handleDeleteAddress(address)}>
                      Delete
                    </Button>
                  )}
                  <Button variant="outlined" color="primary" size="small" onClick={() => onCreateBilling(address)}>
                    Deliver to this Address
                  </Button>
                </Stack>
              }
              sx={{
                p: 3,
                mb: 3,
                borderRadius: 2,
                boxShadow: (theme) => theme.customShadows.card,
              }}
            />
          ))}

          <Stack direction="row" justifyContent="space-between">
            {withSummary && <Button
              size="small"
              color="inherit"
              onClick={onBackStep}
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            >
              Back
            </Button>}

            <Button
              size="small"
              color="primary"
              onClick={addressForm.onTrue}
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Address
            </Button>
          </Stack>
        </Grid>

        {withSummary && <Grid xs={12} md={4}>
          <CheckoutSummary total={checkout.total} subTotal={checkout.subTotal} discount={checkout.discount} />
        </Grid>}
      </Grid>

     {addressForm.value && <AddressNewForm open={addressForm.value} onClose={addressForm.onFalse} onCreate={onCreateBilling} />}
    </>
  );
}

CheckoutBillingAddress.propTypes = {
  checkout: PropTypes.object,
  onBackStep: PropTypes.func,
  onCreateBilling: PropTypes.func,
  withSummary: PropTypes.bool,
};
