// import { useEffect, useState } from 'react';
// material
import {
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Box,
  Card,
  // Switch,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
// components
// import { Link } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import Page from '../components/Page';
// import { ProductSort, ProductFilterSidebar } from '../sections/@dashboard/products';
import { useGetProducts } from 'src/api/product';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// import Image from 'src/components/image/image';
// import Link from 'src/theme/overrides/components/link';
// import { RouterLink } from 'sr c/routes/components';
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/iconify/iconify';
import { ProductItemSkeleton } from 'src/sections/product/product-skeleton';
import { ProductList, CartWidget } from '../sections/@dashboard/products';
import img from '../assets/bg.png';

// mock
// import PRODUCTS from '../_mock/products';
// import axiosInstance from '../utils/axios';

// ----------------------------------------------------------------------
const ProductPlaceholderStyle = styled('img')({
  width: '30%',
  height: '30%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

export default function ProductsShop(props) {
  // const {products=[], categories=[]} = props
  // const [openFilter, setOpenFilter] = useState(false);
  // const [products, categories] = useOutletContext();

  // const handleOpenFilter = () => {
  //   setOpenFilter(true);
  // };

  // const handleCloseFilter = () => {
  //   setOpenFilter(false);
  // };

  const [path, setPath] = useState('');
  const [customerId] = useState('');
  const {
    products: apiProducts,
    categories: apiCategories,
    brands,
    brand_group,
    brand_categories,
    locations,
    locationQuery,
    location,
    productsLoading,
  } = useGetProducts(`${path}`);
  const [searchParams, setSearchParams] = useSearchParams();
  const { totalItems } = useSelector((state) => state.checkout);

  useEffect(() => {
    const _locationId = searchParams.get('location_id');
    const _customerId = searchParams.get('customer_id');
    const _brandId = searchParams.get('brand_id');
    if (_customerId) {
      setPath(`?customer_id=${_customerId}`);
    }
    if (_locationId) {
      setPath(`?location_id=${_locationId}`);
      if (_brandId) {
        setPath(`?location_id=${_locationId}&brand_id=${_brandId}`);
      }
    }
    if (!_customerId && !_locationId) {
      setSearchParams(`customer_id=38`);
      setPath(`?customer_id=38`);
    }
  }, [customerId, searchParams, setSearchParams]);

  const navigate = useNavigate();
  const products = useMemo(() => {
    const q = searchParams.get('q');
    const num = parseInt(q, 10);
    if (q) {
      return apiProducts.filter(
        (p) => p?.name?.toLowerCase().includes(q) || (num && parseInt(p.price || p.actual_price, 10) === num)
      );
    }
    return apiProducts;
  }, [apiProducts, searchParams]);

  const categories = useMemo(() => {
    const brand_id = searchParams.get('brand_id');

    if (brand_id) {
      const brand_groups = brand_group?.filter((bg) => bg.brand_id === Number(brand_id)).map((bg) => bg.group_id);
      const groups = brand_categories?.filter((bc) => brand_groups.includes(bc.group_id)).map((bc) => bc.categories);
      return apiCategories?.filter((c) => groups?.includes(c.id));
    }

    return apiCategories;
  }, [apiCategories, brand_categories, brand_group, searchParams]);

  const [showList, setShowList] = useState('list');
  const handleChange = (e, val) => {
    setShowList(val);
  };

  const sections = {
    LOCATIONS: 'locations',
    BRANDS: 'brands',
    CATEGORIES: 'categories',
  };
  const activeSection = useMemo(() => {
    if (locationQuery) {
      return 'locations';
    }
    if (!locationQuery && brands?.length > 0 && !searchParams?.get('brand_id')) {
      return 'brands';
    }
    if (
      (!locationQuery && brands?.length > 0 && searchParams?.get('brand_id')) ||
      (!locationQuery && brands?.length <= 0)
    ) {
      return 'categories';
    }
    return '';
  }, [brands?.length, locationQuery, searchParams]);

  const handleBack = () => {
    if (activeSection === sections.CATEGORIES && searchParams?.get('brand_id')) {
      searchParams.delete('brand_id');
      setSearchParams(searchParams);
    } else {
      navigate(`/dashboard/products?customer_id=${location?.customer_id?.toString()}`);
    }
  };
  return productsLoading ? (
    <Grid container spacing={4}>
      {Array.from(Array(12).keys()).map((i) => (
        <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
          <ProductItemSkeleton />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Container>
      {(activeSection === sections.BRANDS && searchParams?.get('customer_id')) && (
        <Typography
          variant="subtitle1"
          sx={{ mb: 3, cursor: 'pointer' }}
          onClick={() => {
            navigate(`/dashboard/products?customer_id=${location?.customer_id?.toString()}`);
          }}
        >
          <Iconify icon="mdi:arrow-left" sx={{ mb: -0.5 }} /> Back to all locations
        </Typography>
      )}  

      {(activeSection === sections.CATEGORIES && searchParams?.get('customer_id')) && (
        <Typography variant="subtitle1" sx={{ mb: 3, cursor: 'pointer' }} onClick={handleBack}>
          <Iconify icon="mdi:arrow-left" sx={{ mb: -0.5 }} /> Back to all brands and locations
        </Typography>
      )}

      <Typography variant="h4" sx={{ mb: 3 }}>
        {activeSection === sections.CATEGORIES && `Explore Menu of ${location?.name || '...'}`}
        {activeSection === sections.LOCATIONS && `Choose From Trusted Restaurants in One Single Order`}
        {activeSection === sections.BRANDS && `Choose Brand ...`}
      </Typography>

      {activeSection === sections.BRANDS && (
        <Grid container spacing={3}>
          {brands?.map((b) => (
            <Grid key={b.id} item xs={6} sm={6} md={3}>
              <Card
                onClick={() => {
                  navigate(
                    `/dashboard/products?customer_id=${b?.customer_id}&location_id=${b?.location_id}&brand_id=${b?.id}`
                  );
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover .add-cart-btn': {
                    opacity: 1,
                  },

                  '&:hover': {
                    boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 20px 15px',
                  },
                }}
              >
                <Box sx={{ pt: '100%', position: 'relative' }}>
                  {b.brand_thumbnail ? (
                    <ProductImgStyle
                      alt={`https://syspos.s3.me-central-1.amazonaws.com/media/${b.brand_thumbnail}`}
                      src={`https://syspos.s3.me-central-1.amazonaws.com/media/${b.brand_thumbnail}`}
                    />
                  ) : (
                    <ProductPlaceholderStyle alt={b.brand_name} src={img} />
                  )}
                </Box>

                <Stack spacing={2} sx={{ p: 3 }}>
                  {/* <Typography variant="subtitle2" noWrap> */}
                  {b.brand_name}
                  {/* </Typography> */}

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {/* <ColorPreview colors={colors || []} /> */}
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {activeSection === sections.LOCATIONS && (
        <Grid container spacing={3}>
          {locations?.map((l) => (
            <Grid key={l.id} item xs={12} sm={6} md={3}>
              <Card
                onClick={() => {
                  navigate(`/dashboard/products?customer_id=${l?.customer_id}&location_id=${l.id?.toString()}`);
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover .add-cart-btn': {
                    opacity: 1,
                  },

                  '&:hover': {
                    boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 20px 15px',
                  },
                }}
              >
                <Box sx={{ pt: '100%', position: 'relative' }}>
                  {l.logo_image ? (
                    <ProductImgStyle
                      alt={`https://syspos.s3.me-central-1.amazonaws.com/media/${l.logo_image}`}
                      src={`https://syspos.s3.me-central-1.amazonaws.com/media/${l.logo_image}`}
                    />
                  ) : (
                    <ProductPlaceholderStyle alt={l.name} src={img} />
                  )}
                </Box>

                <Stack spacing={2} sx={{ p: 3 }}>
                  {/* <Typography variant="subtitle2" noWrap> */}
                  {l.name}
                  {/* </Typography> */}

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {/* <ColorPreview colors={colors || []} /> */}
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {activeSection === sections.CATEGORIES && (
        <>
          <Stack
            direction="row"
            gap={1}
            flexWrap="nowrap"
            alignItems="center"
            // justifyContent="flex-start"  
            sx={{ pb: 2, overflowX: 'scroll', position: 'sticky', top: '4rem', zIndex: 1 }}
          >
            {categories.map((c) => (
              <Button
                fullWidth
                onClick={() => {
                  document.getElementById(`cat${c.id}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
                sx={{ minWidth: 'fit-content' }}
                color="primary"
                variant="contained"
                key={c.id}
              >
                {c.name}
              </Button>
            ))}
          </Stack>
          <Box sx={{ textAlign: 'end' }}>
            {/* Show list */}
            {/* <Switch checked={showList} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} /> */}

            <ToggleButtonGroup value={showList} exclusive onChange={handleChange} aria-label="text alignment">
              <ToggleButton value="card" aria-label="left aligned">
                <Iconify icon="bi:grid-3x2-gap-fill" />
              </ToggleButton>
              <ToggleButton value="list" aria-label="centered">
                <Iconify icon="material-symbols:lists-rounded" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <ProductList
            products={products}
            categories={categories}
            location={location}
            isListView={showList === 'list'}
          />
        </>
      )}
      {location?.enable_menu_checkout === 1 && <CartWidget totalItems={totalItems} />}
    </Container>
  );
}
