import axios from 'axios';
// config
// import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ 
  
  // baseURL: "https://dev1.syspos.ae",
  baseURL: "https://app.syspos.ae",  
  // baseURL: "http://localhost:3000",
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance; 

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  userAddress:{
    list:'api/v2/auth/client-users/address'
  },
  orderHistory:{
    list:'api/v2/multi-location-orders'
  },
  product: {
    list: '/api/v2/products/open-menu',
    details: '/api/products/details',
    search: '/api/v2/products/open-menu',
  },
};



