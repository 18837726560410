import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
// import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hook';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/iconify/iconify';
import { Avatar, Typography } from '@mui/material';
import { NAV } from '../config-layout';
// import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';

// ----------------------------------------------------------------------
const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  color: '#2065D1',
}));

export default function NavVertical({ openNav, onCloseNav, location, locationQuery, locations, categories }) {
  const { user } = useMockedUser();

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  // const navData = useNavData();

  const navigation = useMemo(() => {
    if (categories?.length) {
      const navList = categories.map((c) => ({
        title: c?.name || '',
        id: c.id,
        path: '/dashboard/products',
        type: 'category',
        icon: <Iconify icon="ph:dot-duotone" sx={{ width: 35, height: 35 }} />,
      }));
      return [
        {
          items: navList,
        },
      ];
      // return navList;
    }
    if (locations?.length) {
      const navList = locations.map((c) => ({
        title: c?.name || '',
        id: c.id,
        path: '/dashboard/products',
        type: 'location',
        icon: <Iconify icon="mdi:map-marker-circle" height={35} width={35} sx={{ color: 'primary' }} />,
      }));
      return [
        {
          items: navList,
        },
      ];
      // return navList;
    }

    return [
      {
        items: [],
      },
    ];
  }, [categories, locations]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const parseImage = (img) => {
    if (img && img.includes('http')) {
      return img;
    }
    if (img) {
      return `https://syspos.s3.me-central-1.amazonaws.com/media/${img}`;
    }

    return null;
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {/* <Logo sx={{ mt: 3, ml: 4, mb: 1 }} /> */}

      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>{/* <Logo /> */}</Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <AccountStyle>
          {/* <Avatar   ={account.photoURL} alt="photoURL" /> */}
          {/* <Iconify icon="mdi:shop-location" height={35} width={35}/> */}
          {location?.logo_image ? (
            <Avatar src={parseImage(location?.logo_image)} sx={{ height: 40, width: 40 }} />
          ) : (
            <Iconify icon="mdi:shop-location" height={35} width={35} sx={{ color: 'primary' }} />
          )}
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {locationQuery ? locations?.find((l) => l)?.customer_name : location?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {locationQuery ? `(${locations?.length}) locations` : location?.customer_name}
            </Typography>
          </Box>
        </AccountStyle>
      </Box>

      <NavSectionVertical
        data={navigation}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavUpgrade /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
  location: PropTypes.object,
  locations: PropTypes.array,
  categories: PropTypes.array,
  locationQuery: PropTypes.bool,
};
