/* eslint-disable camelcase */
import PropTypes from 'prop-types';
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { addToCart } from 'src/redux/slices/checkout';
import { useDispatch } from 'react-redux';
import Iconify from 'src/components/iconify/iconify';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import FormProvider, { RHFMultiCheckbox, RHFRadioGroup } from 'src/components/hook-form';
import { useSearchParams } from 'react-router-dom';
import { fCurrency } from '../../../utils/format-number';
// components
import Label from '../../../components/label';
// import { ColorPreview } from '../../../components/color-utils';
import img from '../../../assets/bg.png';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const ProductPlaceholderStyle = styled('img')({
  width: '30%',
  height: '30%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  location: PropTypes.object,
};

export default function ShopProductCard({ product, location }) {
  const { id, name, image, price, status, priceSale, actual_price, currency, modifierGroups } = product;
  const available = 1000;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const brand_id = searchParams.get('brand_id');

  const [open, setOpen] = useState(false);

  const handleAddCartWithModifier = async (data) => {
    const allModifiers = [];
    for (let index = 0; index < modifierGroups.length; index += 1) {
      const modifierGroup = modifierGroups[index];
      allModifiers.push(...(modifierGroup?.modifiers || []));
    }

    const singleModifiers = Object.values(data.selectedSingleModifier || {});
    const groupModifiers = data.selectedModifiers || [];

    const selectedModifiers = [...singleModifiers, ...groupModifiers]?.map((mId) =>
      allModifiers?.find((am) => Number(mId) === Number(am?.id))
    );

    setOpen(false);
    const newProduct = {
      ...product,
      id,
      name,
      image,
      available,
      price:
        price +
          [...(selectedModifiers || [])].reduce((sum, item) => parseFloat(sum) + parseFloat(item?.price || 0), 0.0) ||
        0,
      // colors: [colors[0]],
      // size: sizes[0],
      quantity: 1,
      selectedModifiers,
      brand_id,
    };
    try {
      dispatch(addToCart(newProduct));
      enqueueSnackbar(<Typography>{name} added to cart! </Typography>, 'success');
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddCart = async () => {
    if (!location.enable_menu_checkout) {
      enqueueSnackbar(<Typography>Checkout is not enabled for this location</Typography>, {variant:'error'});
      return
    }
    if (modifierGroups?.length > 0) {
      setOpen(true);
    } else {
      const newProduct = {
        ...product,
        id,
        name,
        image,
        available,
        price,
        // colors: [colors[0]],
        // size: sizes[0],
        quantity: 1,
        brand_id,
      };
      console.log('newProduct:------------- ', newProduct);
      try {
        dispatch(addToCart(newProduct));
        enqueueSnackbar(<Typography>{name} added to cart! </Typography>, 'success');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const parseImage = (_img) => {
    if (_img && _img.includes('http')) {
      return _img;
    }
    if (_img) {
      return `https://syspos.s3.me-central-1.amazonaws.com/media/${_img}`;
    }

    return null;
  };
  const NewAddressSchema = Yup.object().shape({});
  const defaultValues = {
    selectedModifiers: [],
  };
  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      handleAddCartWithModifier(data);
    } catch (error) {
      console.error(error);
    }
  });

  const getPrice = (value) => {
    if (Number(value) === 0) {
      return 'Free';
    }
    return `${value} ${currency}`;
  };

  return (
    <>
      <Card
        sx={{
          // '&:hover .add-cart-btn': {
          //   opacity: 1,
          // },
          '&:hover': {
            boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 20px 15px',
          },
        }}
      >
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {status && (
            <Label
              variant="filled"
              color={(status === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {status}
            </Label>
          )}

          {location.enable_menu_checkout === 1 && <Fab
            color="#025844"
            size="medium"
            className="add-cart-btn"
            onClick={handleAddCart}
            disableFocusRipple
            disableRipple
            sx={{
              right: 16,
              bottom: 16,
              zIndex: 9,
              opacity: 1,
              position: 'absolute',
              backgroundColor: '#025844',
              '&:hover': {
                backgroundColor: '#025844',
              },
              // transition: (theme) =>
              //   theme.transitions.create('all', {
              //     easing: theme.transitions.easing.easeInOut,
              //     duration: theme.transitions.duration.shorter,
              //   }),
            }}
          >
            <Iconify icon="ic:round-add-shopping-cart" sx={{ color: '#FFFFFF' }} />
          </Fab>}
          {parseImage(image) ? (
            <ProductImgStyle alt={name} src={parseImage(image)} />
          ) : (
            <ProductPlaceholderStyle alt={name} src={img} />
          )}
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {/* <ColorPreview colors={colors || []} /> */}
            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                  textDecoration: 'line-through',
                }}
              >
                {priceSale && fCurrency(priceSale)}
              </Typography>
              &nbsp;
              {`${fCurrency(actual_price || price)} ${currency}`}
            </Typography>
          </Stack>
        </Stack>
      </Card>

      {open && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>{`${name}`}</DialogTitle>

            <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
              <Stack>
                {modifierGroups?.map((mg) => {
                  const options = mg.modifiers?.map((m) => ({
                    ...m,
                    label: `${m?.name || ''} (${getPrice(m?.actual_price || mg?.price || 0)})`,
                    value: m.id,
                  }));

                  if (mg?.selection_allowed === 1) {
                    return (
                      <RHFRadioGroup
                        name={`selectedSingleModifier.mg-${mg?.id}`}
                        label={`${mg?.name || ''} (Choose any ${mg?.selection_allowed})`}
                        options={options}
                      />
                    );
                  }

                  return (
                    <RHFMultiCheckbox
                      name="selectedModifiers"
                      label={`${mg?.name || ''} (Choose any ${mg?.selection_allowed})`}
                      options={options}
                    />
                  );
                })}
              </Stack>
            </DialogContent>

            <DialogActions>
              <Stack direction="row" spacing={2} justifyContent="flex-end" flexGrow={1}>
                <Button
                  variant="soft"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" disabled={isSubmitting} color="primary" type="submit">
                  Add Item
                </Button>
              </Stack>
            </DialogActions>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
}
