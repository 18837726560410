import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';


import UserNewEditForm from '../../sections/user/user-new-edit-form';

export default function LoginPopup({ open, handleClose }) {
  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
    
      <UserNewEditForm onNextStep={handleClose}/>
    </Dialog>
  );
}

LoginPopup.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
};
