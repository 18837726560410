import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFAutocomplete } from 'src/components/hook-form';
// @mui
import { useTheme } from '@mui/material/styles';
// import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Container } from '@mui/system';
import { areaJSON, countries } from 'src/assets/data';
import { LoadingButton } from '@mui/lab';
import { HEADER, NAV } from '../config-layout';
import {
  AccountPopover,
  // AccountPopover,
  // SettingsButton,
  // LanguagePopover,
  // ContactsPopover,
  // NotificationsPopover,
} from '../_common';
import Iconify from '../../components/iconify';
import { usePopover } from '../../components/custom-popover';
import CheckoutBillingAddress from '../../sections/product/checkout/checkout-billing-address';
import { useCheckout } from '../../sections/product/hooks';
import { useUserAddress } from '../../api/userAddress';
import OrderHistory from './OrderHistory';
import LoginPopup from './LoginPopup';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const popover = usePopover();
  const guestPopover = usePopover();
  const orderHistoryPopover = usePopover();
  const loginPopup = usePopover();
  

  const user = JSON.parse(localStorage.getItem('u') || '{}');

  const isLoggedInUser = user && user?.user_id;
  const [guestAddress, setGuestAddress] = useState({
    country: '',
    city: '',
    state: '',
    name: '',
  });
  const settings = useSettingsContext();
  const handleClose = useCallback(() => {
    popover.onClose();
  }, [popover]);

  const handleGuestClose = useCallback(() => {
    guestPopover.onClose();
  }, [guestPopover]);

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const { addressList } = useUserAddress();

  const addressInUse = useMemo(() => addressList?.find((address) => address.primary), [addressList]);

  const displayAddress = useMemo(
    () => (isLoggedInUser ? addressInUse : guestAddress),
    [addressInUse, guestAddress, isLoggedInUser]
  );
  // const getLocation = () => {
  //   const navigatorLocationOptions = {
  //     enableHighAccuracy: true,
  //     timeout: 5000,
  //     maximumAge: 0,
  //   };

  //   console.log('address in', navigator.geolocation);

  //   navigator.geolocation.getCurrentPosition(
  //     async (position) => {
  //       const { latitude, longitude } = position.coords;
  //       // Fetching address using Google Places API
  //       const response = await fetch(
  //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCuplGu2IBme05N6mFEZIrc8h1Btdav0m8`
  //       );
  //       const data = await response.json();
  //       console.log('address data', data);

  //       const { results } = data;
  //       if (results.length > 0) {
  //         const addressData = results[0].address_components;
  //         // Extracting address components
  //         const address = addressData.map((component) => component.long_name).join(', ');
  //         console.log('address', address);
  //         setLocationData(address);
  //       }
  //     },
  //     (error) => {
  //       console.error('Error getting location:', error);
  //     },
  //     navigatorLocationOptions
  //   );
  // };
  const {
    checkout,
    onBackStep,

    onCreateBilling,
  } = useCheckout();

  const handleAddressPopup = (event) => {
    event.stopPropagation();
    if (isLoggedInUser) popover.onOpen(event);
    else guestPopover.onOpen(event);
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Button variant="text" startIcon={<Iconify icon="mdi:location" />} onClick={handleAddressPopup}>
        {displayAddress?.name || 'Set Address'}

        <Iconify icon="material-symbols:edit" sx={{ ml: 2 }} />
      </Button>

      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1 }}>
        {/* <Searchbar /> */}
        {/* <LanguagePopover />

        <NotificationsPopover />

        <ContactsPopover />

        <SettingsButton />

         */}
        <AccountPopover openOrderHistory={orderHistoryPopover.onOpen} openAddress={handleAddressPopup} openLogin={loginPopup.onOpen} />
      </Stack>
    </>
  );

  const NewAddressSchema = Yup.object().shape({
    city: Yup.string().required('City is required'),
    state: Yup.string().required('Area is required'),
    country: Yup.string().required('Country is required'),
  });

  const defaultValues = {
    city: '',
    state: '',
    country: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const { watch, handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      setGuestAddress({
        city: data.city,
        state: data.state,
        country: data.country,
        name: `${data.state}, ${data.city}, ${data.country}`,
      });
    } catch (error) {
      console.error(error);
    }

    guestPopover.onClose();
  });

  console.log('guestAddress', guestAddress);
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={popover.open} onClose={handleClose}>
        <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{ mb: 10 }}>
          <Typography variant="h4" sx={{ my: { xs: 3, md: 5 } }}>
            Manage Address
          </Typography>
          <CheckoutBillingAddress
            checkout={checkout}
            onBackStep={onBackStep}
            onCreateBilling={onCreateBilling}
            withSummary={false}
          />
        </Container>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={guestPopover.open} onClose={handleAddressPopup}>
        <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{ mb: 10 }}>
          <Typography variant="h4" sx={{ my: { xs: 3, md: 5 } }}>
            Location
          </Typography>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <DialogContent dividers>
              <Stack spacing={3}>
                <RHFAutocomplete
                  name="country"
                  label="Country"
                  sx={{ mt: 2 }}
                  options={countries.map((country) => country.label)}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => {
                    const { code, label, phone, enabled } = countries.filter((country) => country.label === option)[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <MenuItem {...props} disabled={!enabled} key={label}>
                        <Iconify key={label} icon={`circle-flags:${code.toLowerCase()}`} width={28} sx={{ mr: 1 }} />
                        {label} ({code}) {phone}
                      </MenuItem>
                    );
                  }}
                />

                <RHFAutocomplete
                  name="city"
                  label="Town / City"
                  options={[...new Set(areaJSON.map((area) => area.city))]}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => {
                    const { city } = areaJSON?.find((area) => area.city === option) || {};

                    if (!city) {
                      return null;
                    }

                    return (
                      <li {...props} key={city}>
                        <Iconify key={city} icon="mdi:location" width={28} sx={{ mr: 1 }} />
                        {city}
                      </li>
                    );
                  }}
                />
                <RHFAutocomplete
                  name="state"
                  label="Area"
                  options={[...new Set(areaJSON.filter((a) => a.city === watch('city')).map((a) => a.area))]}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => {
                    const { area } = areaJSON?.find((a) => a.area === option) || {};

                    if (!area) {
                      return null;
                    }

                    return (
                      <li {...props} key={area}>
                        <Iconify key={area} icon="mdi:location" width={28} sx={{ mr: 1 }} />
                        {area}
                      </li>
                    );
                  }}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" variant="outlined" onClick={handleGuestClose}>
                Cancel
              </Button>

              <LoadingButton type="submit" variant="contained">
                Confirm
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </Container>
      </Dialog>
      <OrderHistory open={orderHistoryPopover.open} handleClose={orderHistoryPopover.onClose} />
      <LoginPopup open={loginPopup.open} handleClose={loginPopup.onClose} />
      
      <AppBar
        sx={{
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(lgUp && {
            width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
            height: HEADER.H_DESKTOP,
            ...(offsetTop && {
              height: HEADER.H_DESKTOP_OFFSET,
            }),
            ...(isNavHorizontal && {
              width: 1,
              bgcolor: 'background.default',
              height: HEADER.H_DESKTOP_OFFSET,
              borderBottom: `dashed 1px ${theme.palette.divider}`,
            }),
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_MINI + 1}px)`,
            }),
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
