import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
// routes
//
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Iconify from '../../iconify';
//
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, depth, active, config, externalLink, ...other }) {
  const { title, path, icon, info, children, disabled, caption, roles, type } = item;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem disableGutters disabled={disabled} active={active} depth={depth} config={config} {...other}>
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          secondary={
            caption ? (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            textTransform: 'capitalize',
            fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  // External link
  if (externalLink)
    return (
      <Link
        href={path}
        target="_blank"
        rel="noopener"
        underline="none"
        color="inherit"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link
      // component={RouterLink}
      onClick={() => {
        if (type === 'category') {
          searchParams.set('current', `cat${item.id}`);
          const location_id = searchParams.get('location_id');
          const customer_id = searchParams.get('customer_id');
          console.log('location...', location_id);
          if (pathname.includes('checkout')) {
            if (location_id && customer_id)
              navigate(`/dashboard/products?customer_id=${customer_id}&location_id=${location_id}`);
            else if (location_id) {
              navigate(`/dashboard/products?location_id=${location_id}`);
            } else if (customer_id) {
              navigate(`/dashboard/products?customer_id=${customer_id}`);
            }
          } else {
            setSearchParams(searchParams);
            document.getElementById(`cat${item.id}`).scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        } else if (type === 'location') {
          searchParams.set('location_id', `${item.id}`);
          setSearchParams(searchParams);
        }
      }}
      // to={active ? window.location.pathname + window.location.search : path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  config: PropTypes.object,
  depth: PropTypes.number,
  externalLink: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
};
