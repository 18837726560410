import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';
import _ from 'lodash';

// ----------------------------------------------------------------------

export function useOrderHistory() {
  const user = JSON.parse(localStorage.getItem('u') || '{}');
  const URL = user && user?.user_id ? [endpoints.orderHistory.list, { params: { user_id: user?.user_id } }] : null;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });
  const memoizedValue = useMemo(
    () => ({
      orderHistoryList:_.groupBy((data?.data || []), 'multi_location_orders_short_id'),
      // _.groupBy(data, 'multi_location_orders_id'),
       orderHistoryLoading: isLoading,
       orderHistoryError: error,
       orderHistoryValidating: isValidating,
       orderHistoryEmpty: data && data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  const mutateOrderHistory = () => {
    mutate(URL);
  };

  memoizedValue.mutateOrderHistory = mutateOrderHistory;

  return memoizedValue;
}
