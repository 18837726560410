import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGetProducts } from 'src/api/product';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const [path, setPath] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const _locationId = searchParams.get('location_id');
  const _customerId = searchParams.get('customer_id');
    const { products: apiProducts, categories, locations, locationQuery, location } = useGetProducts(`${path}`);
  const { pathname } = useLocation();

  useEffect(() => {

    if (_customerId) {
      setPath(`?customer_id=${_customerId}`);
    }
    if (_locationId) {
      setPath(`?location_id=${_locationId}`);
    }
    if (!_customerId && !_locationId && !pathname.includes('checkout')) {
      setSearchParams(`customer_id=38`);
      setPath(`?customer_id=38`);
    }
  }, [_customerId, _locationId, pathname, searchParams, setSearchParams]);

  const products = useMemo(() => {
    const q = searchParams.get('q');
    const num = parseInt(q, 10);
    if (q) {
      return apiProducts.filter(
        (p) => p?.name?.toLowerCase().includes(q) || (num && parseInt(p.price || p.actual_price, 10) === num)
      );
    }
    return apiProducts;
  }, [apiProducts, searchParams]);

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = (
    <NavVertical
      openNav={nav.value}
      onCloseNav={nav.onFalse}
      location={location}
      locationQuery={locationQuery}
      locations={locations}
      categories={categories}
    />
  );

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main products={products || []} categories={categories || []}>
          {children}
        </Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main
          products={products || []}
          categories={categories || []}
          locations={locations}
          isLocationQuery={locationQuery}
        >
          {children}
        </Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
