import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

export function useUserAddress() {
  const user = JSON.parse(localStorage.getItem('u') || '{}');
  const URL = user && user?.user_id ? [endpoints.userAddress.list, { params: { id: user?.user_id } }] : null;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });
  const memoizedValue = useMemo(
    () => ({
      addressList:
        data?.address && data?.address?.length > 0
          ? data.address?.map((d) => ({
              id: d.id,
              primary: d.isDefault === 1,
              name: `${d.address_type} - ${d.address_line_1}`,
              email: '-',
              fullAddress: `${d.address_line_1}, ${d.address_line_2}, ${d.city}, ${d.state}, ${d.country}, ${d.zipcode}`,
              phoneNumber: '',
              company: '',
              addressType: d.address_type,
            }))
          : [],
      addressLoading: isLoading,
      addressError: error,
      addressValidating: isValidating,
      addressEmpty: data?.address && data?.address.length,
    }),
    [data?.address, error, isLoading, isValidating]
  );

  const mutateUserAddress = () => {
    mutate(URL);
  };

  memoizedValue.mutateUserAddress = mutateUserAddress;

  return memoizedValue;
}
