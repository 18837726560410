import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// assets
import { countries, areaJSON } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFCheckbox, RHFTextField, RHFRadioGroup, RHFAutocomplete } from 'src/components/hook-form';
import { useSnackbar } from 'notistack';
import axiosInstance from 'src/utils/axios';
import { useEffect, useRef, useState } from 'react';
import { useUserAddress } from 'src/api/userAddress';
import { MenuItem } from '@mui/material';

// ----------------------------------------------------------------------

export default function AddressNewForm({ open, onClose, onCreate }) {
  const { enqueueSnackbar } = useSnackbar();
  const autocompleteRef = useRef(null);
  const { mutateUserAddress } = useUserAddress();

  const NewAddressSchema = Yup.object().shape({
    // name: Yup.string().required('Fullname is required'),
    // phoneNumber: Yup.string().required('Phone number is required'),
    addressLine1: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('Area is required'),
    country: Yup.string().required('Country is required'),
    zipCode: Yup.string(),
    // not required
    addressType: Yup.string(),
    primary: Yup.boolean(),
  });

  const defaultValues = {
    name: '',
    city: '',
    state: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    primary: true,
    phoneNumber: '',
    addressType: 'Home',
    country: '',
  };


  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const { setValue, watch } = methods;
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const handlePlaceChanged = () => {
  //   console.log(' in autocomplete  here', autocompleteRef.current);
  //   if (autocompleteRef.current !== null) {
  //     const place = autocompleteRef.current.getPlace();
  //     console.log('place', place);
  //   }
  // };
  const initializeAutocomplete = () => {
    if (window.google) {
      // console.log(' in autocomplete in google', document.getElementById('auto-address-1'));
      // const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('auto-address-1'), {
      //   types: ['geocode'],
      // });
      // console.log('in autocomplete', autocomplete);

      // autocomplete.addListener('place_changed', handlePlaceChanged);
    }
  };

  useEffect(() => {
    initializeAutocomplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log('in submit', data);
      const user = JSON.parse(localStorage.getItem('u') || '{}');
      const schema = {
        client_user_id: data.client_user_id,
        address_type: data.addressType,
        address_line_1: data.addressLine1,
        address_line_2: data.addressLine2,
        city: data.city,
        state: data.state,
        zipcode: data.zipCode,
        country: data.country,
        isDefault: data.primary,
      };
      const response = await axiosInstance.post(`/api/v2/auth/client-users/address?id=${user?.user_id}`, schema);
      if (response?.data?.success) {
        console.info('DATA', response?.data);
        enqueueSnackbar('Address Added successfully!');
        onCreate({
          name: `${data.addressType} - ${data.addressLine1}`,
          phoneNumber: '-',
          fullAddress: `${data.addressLine1},${data.address_line_2}, ${data.city}, ${data.state}, ${data.country}, ${data.zipCode}`,
          addressType: data.addressType,
          primary: data.primary,
        });
        onClose();
        mutateUserAddress();
      } else {
        enqueueSnackbar(response?.data?.error?.toString());
      }
    } catch (error) {
      console.error(error);
    }
  });
  const [locationData, setLocationData] = useState('Get my current address');

  const getLocation = () => {
    const navigatorLocationOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    console.log('address in', navigator.geolocation);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        // Fetching address using Google Places API
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCuplGu2IBme05N6mFEZIrc8h1Btdav0m8`
        );
        const data = await response.json();
        console.log('address data', data);

        const { results } = data;
        if (results.length > 0) {
          const addressData = results[0].address_components;
          const address = addressData.map((component) => component.long_name).join(', ');
          console.log('address', address);
          setLocationData(address);
          setValue('addressLine1', address);
          let addressLine2 = '';
          // eslint-disable-next-line no-restricted-syntax
          for (const component of addressData) {
            if (component.types.includes('street_number')) {
              addressLine2 += ` ${component.long_name}`;
            } else if (component.types.includes('route')) {
              addressLine2 += ` ${component.long_name}`;
            } else if (component.types.includes('neighborhood')) {
              addressLine2 += ` ${component.long_name}`;
            } else if (component.types.includes('sublocality_level_1')) {
              addressLine2 += ` ${component.long_name}`;
            } else if (component.types.includes('locality')) {
              setValue('city', component.long_name);
            } else if (component.types.includes('administrative_area_level_1')) {
              setValue('state', component.short_name);
            } else if (component.types.includes('postal_code')) {
              setValue('zipCode', component.long_name);
            }
          }

          setValue('addressLine2', addressLine2);
          // Extracting address components
        }
      },
      (error) => {
        console.error('Error getting location:', error);
      },
      navigatorLocationOptions
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>New address</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <Button variant="text" startIcon={<Iconify icon="mdi:location" />} onClick={getLocation}>
              {locationData}
            </Button>

            <RHFRadioGroup
              row
              name="addressType"
              options={[
                { label: 'Home', value: 'Home' },
                { label: 'Office', value: 'Office' },
              ]}
            />
            <RHFAutocomplete
              name="country"
              label="Country"
              options={countries.map((country) => country.label)}
              getOptionLabel={(option) => option}
              
              renderOption={(props, option) => {
                const { code, label, phone, enabled } = countries.filter((country) => country.label === option)[0];

                if (!label) {
                  return null;
                }

                return (
                  <MenuItem {...props} disabled={!enabled} key={label} >
                    <Iconify key={label} icon={`circle-flags:${code.toLowerCase()}`} width={28} sx={{ mr: 1 }} />
                    {label} ({code}) {phone}
                  </MenuItem>
                );
              }}
            />

            <RHFAutocomplete
              name="city"
              label="Town / City"
              options={[...new Set(areaJSON.map((area) => area.city))]}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => {
                const { city } = areaJSON?.find((area) => area.city === option) || {};

                if (!city) {
                  return null;
                }

                return (
                  <li {...props} key={city}>
                    <Iconify key={city} icon="mdi:location" width={28} sx={{ mr: 1 }} />
                    {city}
                  </li>
                );
              }}
            />
            <RHFAutocomplete
              name="state"
              label="Area"
              options={[...new Set(areaJSON.filter(a=> a.city === watch('city')).map((a) => a.area))]}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => {
                const { area } = areaJSON?.find((a) => a.area === option) || {};

                if (!area) {
                  return null;
                }

                return (
                  <li {...props} key={area}>
                    <Iconify key={area} icon="mdi:location" width={28} sx={{ mr: 1 }} />
                    {area}
                  </li>
                );
              }}
            />

            <RHFTextField name="addressLine1" label="Address Line 1" inputRef={autocompleteRef} />
            <RHFTextField name="addressLine2" label="Address Line 2" />

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
            >
              {/* <RHFTextField name="city" label="Town / City" /> */}

              {/* <RHFTextField name="state" label="Area" /> */}

              <RHFTextField name="zipCode" label="Zip/Code" />
            </Box>

            <RHFCheckbox name="primary" label="Use this address as default." />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Deliver to this Address
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

AddressNewForm.propTypes = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  open: PropTypes.bool,
};
