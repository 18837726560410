import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';
import { useOrderHistory } from 'src/api/orderHistory';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TableHeadCustom } from 'src/components/table';
import OrderHistoryItem from './OrderHistoryItem';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
function Row(props) {
  const { rows } = props;
  const [open, setOpen] = React.useState(false);
  const row = {
    id: rows[0].multi_location_orders_id,
    subtotal: rows[0].multi_location_orders_subtotal,
    vat: rows[0].multi_location_orders_vat,
    total: rows[0].multi_location_orders_total,
    uuid: rows[0].multi_location_orders_uuid,
    short_id: rows[0].multi_location_orders_short_id,
    created_at: rows[0].multi_location_orders_created_at,
    status: rows[0].multi_location_orders_status,
    discount_amount: rows[0].multi_location_orders_discount_amount,
    updated_at: rows[0].multi_location_orders_updated_at,
    note: rows[0].multi_location_orders_note,
    user_address: rows[0].multi_location_orders_user_address,
    user_email: rows[0].multi_location_orders_user_email,
    user_phone: rows[0].multi_location_orders_user_phone,
    payment_method: rows[0].multi_location_orders_payment_method,
    history: [],
  };

  const TABLE_HEAD = [
    { id: 'product', label: 'Product' },
    { id: 'price', label: 'Price' },
    { id: 'quantity', label: 'Quantity' },
    { id: 'totalAmount', label: 'Total Price', align: 'right' },
  ];

  const renderCustomer = (
    <>
      <CardHeader title="Customer Details" />
      <Stack direction="row" alignItems="center" sx={{ p: 1, px: 3, typography: 'body2' }}>
        <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
          Phone
        </Box>
        {row.user_phone || ''}
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ p: 1, px: 3, typography: 'body2' }}>
        <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
          Email
        </Box>
        {row.user_email || '-'}
      </Stack>
    </>
  );

  const renderDelivery = (
    <>
      <CardHeader title="Address" />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Address
          </Box>
          {row.user_address}
        </Stack>
      </Stack>
    </>
  );

  const renderPayment = (
    <>
      <CardHeader title="Payment" />
      <Stack direction="row" alignItems="center" sx={{ p: 3, typography: 'body2' }}>
        <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
          Payment Method
        </Box>
        {row.payment_method || ''}
      </Stack>
    </>
  );

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Iconify icon="solar:alt-arrow-up-linear" /> : <Iconify icon="solar:alt-arrow-down-linear" />}
          </IconButton>
        </TableCell>
        <TableCell>{row.short_id}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">{row.created_at}</TableCell>
        <TableCell align="right">{row.subtotal}</TableCell>
        <TableCell align="right">{row.vat}</TableCell>
        <TableCell align="right">{row.discount_amount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack direction="row">
              <Card sx={{ margin: 1, minWidth: 500 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom component="div">
                    Invoice Details
                  </Typography>
                  <Table>
                    <TableHeadCustom headLabel={TABLE_HEAD} />

                    <TableBody>
                      {rows.map((invoiceItem) => (
                        <OrderHistoryItem
                          key={invoiceItem.id}
                          row={{
                            name: invoiceItem.name,
                            price: invoiceItem.price,
                            quantity: invoiceItem.qty,
                            available: true,
                            image: '',
                            selectedModifiers: [],
                            currency: 'AED',
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
              <Card sx={{ margin: 1, minWidth: 500 }}>
                {renderCustomer}
                <Divider sx={{ borderStyle: 'dashed' }} />
                {renderDelivery}
                <Divider sx={{ borderStyle: 'dashed' }} />
                {renderPayment}
              </Card>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
Row.propTypes = {
  rows: PropTypes.any,
};
export default function OrderHistory({ open, handleClose }) {
  const { orderHistoryList, orderHistoryLoading, orderHistoryValidating, mutateOrderHistory } = useOrderHistory();
  const handleRefresh = () => {
    mutateOrderHistory()
  }
  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} scroll="body">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            {/* <CloseIcon /> */}
            <Iconify icon="eva:close-fill" />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Order History
          </Typography>

          <Button autoFocus color="inherit" onClick={handleRefresh}>
            Refresh
          </Button>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Card>
        <CardContent>
        <TableRow>{orderHistoryLoading || orderHistoryValidating ? 'Loading new invoices...' : ''}</TableRow>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Invoice Id</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Subtotal</TableCell>
                  <TableCell align="right">Vat</TableCell>
                  <TableCell align="right">Discount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(orderHistoryList).map(([shortId, rows]) => (
                  <Row key={shortId} rows={rows} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* <List>
          <ListItemButton>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText
              primary="Default notification ringtone"       
              secondary="Tethys"
            />
          </ListItemButton>
        </List> */}
    </Dialog>
  );
}

OrderHistory.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
};
