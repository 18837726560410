import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

export function useGetProducts(query) {
  const URL = query ? endpoints.product.search + query : endpoints.product.search;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });
  const length = 10000;
  const memoizedValue = useMemo(
    () => ({
      products:
        data?.products?.map((p) => ({
          ...p,
          currency: data?.location?.currency || 'AED',
          menu_sort_order: p.menu_sort_order === 0 ? length : p.menu_sort_order,
        })) || [],
      categories: data?.categories || [],
      brands: data?.brands || [],
      brand_group: data?.brand_group || [],
      brand_categories: data?.brand_categories || [],
      locations: data?.locations || [],
      location: data?.location || null,
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating,
      productsEmpty: data?.products && data?.products.length,
      locationQuery: data?.locationQuery,
    }),
    [
      data?.brand_categories,
      data?.brand_group,
      data?.brands,
      data?.categories,
      data?.location,
      data?.locationQuery,
      data?.locations,
      data?.products,
      error,
      isLoading,
      isValidating,
      length,
    ]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetProduct(productId) {
  // const URL = productId ? [endpoints.product.details, { params: { productId } }] : null;

  // const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const data = {
    product: {
      id: 9656,
      name: 'Toned milk',
      description: null,
      price: 20.87,
      stock: 0,
      type: 'Category 1',
      image: '',
      customer_id: 38,
      location_id: 59,
      is_addon: 0,
      addons: '[]',
      bundle_items: '[]',
      is_disabled: 0,
      discount_pct: 0,
      modifiers: '4',
      is_archive: 0,
      barcode_type: null,
      barcode_value: null,
      is_open_price: 0,
      is_combo: 0,
      sync_deliverect: 0,
      sync_otter: 0,
      new_cost_date: null,
      product_cost: 0,
      is_weightage_item: null,
      unit_id: null,
      actual_price: 20.87,
      old_price: null,
      vat: null,
      localized: null,
      reviews: [],
      sizes: [],
      colors: [],
      newLabel: '',
      available: true,
      priceSale: 99,
      saleLabel: '',
      totalRatings: 5,
      totalReviews: 0,
      inventoryType: '',
      subDescription: '',
    },
  };
  const isLoading = false;
  const error = false;
  const isValidating = false;
  const memoizedValue = useMemo(
    () => ({
      product: data?.product,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [data?.product, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useSearchProducts(query) {
  const URL = query ? [endpoints.product.search, { params: { query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.results || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
