import { useLocation, matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useActiveLink(path, deep = true, id) {
  const { pathname, search } = useLocation();

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) && search.includes(`cat${id}`) : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) && search.includes(`cat${id}`) : false;

  return deep ? deepActive : normalActive;
}
