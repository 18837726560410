import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import Divider from  '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
// utils
import { fCurrency } from 'src/utils/format-number';
import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function OrderHistoryItem({ row }) {
  const { name, price, quantity, selectedModifiers, currency } = row;
  return (
    <TableRow flexWrap="wrap">
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <Avatar variant="rounded" alt={name} src={parseImage(image)} sx={{ width: 64, height: 64, mr: 2 }} /> */}

        <Stack spacing={0.5}>
          <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
            {name}
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ typography: 'body2', color: 'text.secondary', flexWrap: 'wrap', maxWidth: 240 }}
          >
            {selectedModifiers?.map((m) => (
              <Label sx={{ ml: 0.5 }}>
                {' '}
                {m?.name} ({m?.price} {currency}){' '}
              </Label>
            ))}
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>{fCurrency(price)}</TableCell>

      <TableCell>
        <Box sx={{ width: 88, textAlign: 'right' }}>
          <Typography>{quantity}</Typography>
        </Box>
      </TableCell>

      <TableCell align="right">{fCurrency(price * quantity)}</TableCell>
    </TableRow>
  );
}

OrderHistoryItem.propTypes = {
  row: PropTypes.object,
};
