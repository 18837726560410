import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
// import Divider from  '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// utils
import { fCurrency } from 'src/utils/format-number';
// components
// import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
// import { ColorPreview } from 'src/components/color-utils';
//
import Label from 'src/components/label';
import IncrementerButton from '../common/incrementer-button';

// ----------------------------------------------------------------------

export default function CheckoutCartProduct({ row, onDelete, onDecrease, onIncrease }) {
  const { name, price, quantity, available, image, selectedModifiers, currency } = row;
  console.log('row', row);
  const parseImage = (_img) => {
    if (_img && _img.includes('http')) {
      return _img;
    }
    if (_img) {
      return `https://syspos.s3.me-central-1.amazonaws.com/media/${_img}`;
    }

    return null;
  };
  return (
    <TableRow flexWrap="wrap">
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar variant="rounded" alt={name} src={parseImage(image)} sx={{ width: 64, height: 64, mr: 2 }} />

        <Stack spacing={0.5}>
          <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
            {name}
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ typography: 'body2', color: 'text.secondary', flexWrap: 'wrap', maxWidth: 240 }}
          >
            {selectedModifiers?.map((m) => (
              <Label sx={{ ml: 0.5 }}>
                {' '}
                {m?.name} ({m?.price} {currency}){' '}
              </Label>
            ))}
            {/* <Divider orientation="vertical" sx={{ mx: 1, height: 16 }} /> */}
            {/* <ColorPreview colors={colors} /> */}
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        {fCurrency(
          price 
        )}
      </TableCell>

      <TableCell>
        <Box sx={{ width: 88, textAlign: 'right' }}>
          <IncrementerButton
            quantity={quantity}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            disabledDecrease={quantity <= 1}
            disabledIncrease={quantity >= available}
          />

          <Typography variant="caption" component="div" sx={{ color: 'text.secondary', mt: 1 }}>
            Adjust quantity
          </Typography>
        </Box>
      </TableCell>

      <TableCell align="right">{fCurrency(price * quantity)}</TableCell>

      <TableCell align="right" sx={{ px: 1 }}>
        <IconButton onClick={onDelete}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

CheckoutCartProduct.propTypes = {
  row: PropTypes.object,
  onDelete: PropTypes.func,
  onDecrease: PropTypes.func,
  onIncrease: PropTypes.func,
};
