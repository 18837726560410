import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line import/no-unresolved
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShopProductCard from './ProductCard';
import Iconify from '../../../components/iconify';
import ProductCardHorizontal from './ProductCardHorizontal';
// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  location: PropTypes.any,
  isListView: PropTypes.bool,
};

export default function ProductList({ products, categories, location, isListView, ...other }) {
  const [expanded, setExpanded] = useState([]);

  const toggleElement = (arr, val) => (arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val]);

  const handleChange = (panel) => () => {
    setExpanded((data) => toggleElement(data, panel));
  };

  useEffect(() => {
    setExpanded(categories.map((s) => s.id));
  }, [categories]);

  return (
    <>
      {categories.map((c) => (
        <Accordion expanded={expanded.includes(c.id)} onChange={handleChange(c.id)} key={c.id} elevation={0}>
          <AccordionSummary
            expandIcon={<Iconify icon="eva:chevron-up-fill" sx={{ height: 50, width: 50, color:'primary'}} color='primary'/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography id={`cat${c.id}`} sx={{ width: '33%', flexShrink: 0, textTransform: 'uppercase',  }} color='primary' variant="h6">
              {c.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Grid container spacing={3} {...other}>
              {products
                .filter((p) => p.type === c.name)
                .sort((a, b) => {
                  if (a.menu_sort_order > b.menu_sort_order) {
                    return 1;
                  }
                  if (a.menu_sort_order < b.menu_sort_order) {
                    return -1;
                  }
                  return 0;
                })
                .map((product) => (
                  <Grid key={product.id} item xs={12} sm={6} md={isListView ? 6 : 3}>
                    {isListView ? <ProductCardHorizontal product={product} location={location}/> : <ShopProductCard product={product} location={location} />}
                  </Grid>
                ))}
              {products.filter((p) => p.type === c.name).length === 0 && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography id={`catempty${c.id}`} sx={{ width: '33%', flexShrink: 0 }} variant="p">
                    No products found
                  </Typography>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
