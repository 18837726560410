import { m } from 'framer-motion';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Button } from '@mui/material';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Order History',
  },
  {
    label: 'My Addresses',
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover({openOrderHistory, openAddress, openLogin}) {
  const userDetails = JSON.parse(localStorage.getItem('u') || '{}');

  const isLoggedInUser = userDetails?.user_id || false ;

  const { user } = useMockedUser();

  const { logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('u');
      popover.onClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (e, {label}) => {
    if(label === 'Order History')
    {
      openOrderHistory(e)
    }
    else if (label === 'My Addresses')
    {
      openAddress(e)
    }
    popover.onClose();
    // router.push(path);
  };

  return (
    <>
      {isLoggedInUser ? (
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={popover.onOpen}
          sx={{
            width: 40,
            height: 40,
            background: (theme) => alpha(theme.palette.grey[500], 0.08),
            ...(popover.open && {
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
            }),
          }}
        >
          <Avatar
            src={user?.photoURL}
            alt={null}
            sx={{
              width: 36,
              height: 36,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
            }}
          />
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={openLogin}>
          Log in
        </Button>
      )}

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {isLoggedInUser ? userDetails?.phone_number : 'Hello Guest'}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {isLoggedInUser ? '': 'Please login'}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem  key={option.label} onClick={(e) => handleClickItem(e,option )}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {isLoggedInUser && <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
          Logout
        </MenuItem>}
      </CustomPopover>
    </>
  );
}


AccountPopover.propTypes = {
  openOrderHistory: PropTypes.func,
  openAddress: PropTypes.func,
  openLogin: PropTypes.func,
};
