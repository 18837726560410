import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import axiosInstance from 'src/utils/axios';

// ----------------------------------------------------------------------

export default function UserOTPForm({ userDetails, onNextStep }) {
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    otp: Yup.number('Please enter OTP').required('OTP is required'),
  });

  const defaultValues = useMemo(
    () => ({
      otp: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (data.otp) {
        const schema = {
          phone_number: userDetails.phone_number,
          country_code: userDetails.country_code,
          otp: data.otp,
        };
        const response = await axiosInstance.post('/api/v2/auth/client-users/verify-otp/', schema);
        if (response?.data?.success) {
          console.info('DATA', response?.data);
          enqueueSnackbar('OTP Verified successfully!');
          localStorage.setItem(
            'u',
            JSON.stringify({
              ...response?.data?.data,
              phone_number: userDetails.phone_number,
              country_code: userDetails.country_code,
            })
          );
          onNextStep();
        } else {
          enqueueSnackbar(response?.data?.error?.toString());
        }
      } else {
        enqueueSnackbar('Please enter OTP!');
      }
    } catch (error) {
      enqueueSnackbar(error?.error?.toString());
      console.error(error);
    }
  });

  const onClickResend = async () => {
    const schema = {
      phone_number: userDetails.phone_number,
      country_code: userDetails.country_code,
    };
    const response = await axiosInstance.post('/api/v2/auth/client-users/generate-otp/', schema);
    if (response?.data?.success) {
      console.info('DATA', response?.data);
      enqueueSnackbar('OTP sent successfully!');
    } else {
      enqueueSnackbar(response?.data?.error?.toString());
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="otp" type="number" label="Enter OTP here" />
            </Box>
            <Stack sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 3 }}>
            <LoadingButton onClick={onClickResend} variant="text" loading={isSubmitting}>
                Resend OTP
              </LoadingButton>
              <LoadingButton type="submit" color="primary" variant="contained" loading={isSubmitting}>
                Next
              </LoadingButton>
              
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

UserOTPForm.propTypes = {
  userDetails: PropTypes.object,
  onNextStep: PropTypes.func,
};
